@import "app/global.scss";
.search {
  position: relative;

  .search_render {
    z-index: 88;
    width: 100%;
    background-color: #f5f5f5;

    .tags {
      width: calc(100% - 60px);
      padding: 12px 30px 10px 30px;
      display: flex;
      .tags_item {
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        color: #999999;
        line-height: 22px;
        padding-right: 44px;
        // margin-right: 44px;
      }
      .tags_selItem {
        color: #956e7d;
      }
    }

    .cuttingLine {
      width: 100%;
      border-top: 1px solid #ebebeb;
    }

    .all {
      width: calc(100% - 60px);
      padding: 18px 30px 24px 30px;

      .all_work {
        margin-bottom: 20px;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          .header_name {
            font-weight: 600;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
        }
      }

      .all_item {
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .header_name {
            font-weight: 600;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
        }
      }
    }

    .item {
      width: calc(100% - 60px);
      padding: 18px 30px 24px 30px;

      .item_pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        :global {
          .ant-pagination-item-link {
            background: #ffffff;
            border-radius: 4px;
            margin-right: 12px;
          }
          .ant-pagination-item {
            background: #ffffff;
            border-radius: 4px;
            color: #956e7d;
            margin-right: 12px;
          }
          .ant-pagination-item-active {
            background: #956e7d;
            border-radius: 4px;
            font-family: 'Gilroy-Light';
            font-weight: 300;
            font-size: 14px;
            margin-right: 12px;

            a {
              color: #ffffff !important;
            }
          }
        }
      }
    }

    .work {
      width: calc(100% - 60px);
      padding: 18px 30px 24px 30px;
      .work_pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        :global {
          .ant-pagination-item-link {
            background: #ffffff;
            border-radius: 4px;
            margin-right: 12px;
          }
          .ant-pagination-item {
            background: #ffffff;
            border-radius: 4px;
            color: #956e7d;
            margin-right: 12px;
          }
          .ant-pagination-item-active {
            background: #956e7d;
            border-radius: 4px;
            font-family: 'Gilroy-Light';
            font-weight: 300;
            font-size: 14px;
            margin-right: 12px;

            a {
              color: #ffffff !important;
            }
          }
        }
      }
    }

    .towTier {
      width: 100%;
      .towTier_header {
        width: calc(100% - 60px);
        padding: 12px 30px 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        :global {
          .ant-btn-link {
            padding: 0;
            font-size: 16px;
          }
        }
        .towTier_header_workName {
          font-weight: 600;
          font-size: 14px;
          color: #999999;
          line-height: 20px;
        }
      }
      .towTier_items {
        width: calc(100% - 60px);
        padding: 20px 30px 24px 30px;

        .towTier_items_pagination {
          z-index: 999999 !important;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 20px;

          :global {
            .ant-pagination-item-link {
              background: #ffffff;
              border-radius: 4px;
              margin-right: 12px;
            }
            .ant-pagination-item {
              background: #ffffff;
              border-radius: 4px;
              color: #956e7d;
              margin-right: 12px;
            }
            .ant-pagination-item-active {
              background: #956e7d;
              border-radius: 4px;
              font-family: 'Gilroy-Light';
              font-weight: 300;
              font-size: 14px;
              margin-right: 12px;

              a {
                color: #ffffff !important;
              }
            }
          }
        }
      }
    }
  }

  :global {
    .ant-select-dropdown {
      z-index: 88 !important;
      padding: 0;
      background-color: #f5f5f5;
      border-radius: 10px;
    }
  }

  .search_icon {
    position: absolute;
    top: 13px;
    left: 12px;
  }

  .clear_icon {
    position: absolute;
    top: 8px;
    right: 13px;
  }
}

.pagesearch {
  :global {
    .ant-select-dropdown {
      top: 52px !important;
    }
  }
}

.homesearch {
  :global {
    .ant-select-dropdown {
      top: 52px !important;
      bottom: auto !important;
    }
  }
}
