@import "app/global.scss";
.productT {
  width: 240px;
  height: 490px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .productT_title {
    margin-top: 12px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    color: #3d3d3d;
  }

  .productT_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .productT_list_item {
      width: 134px;
      height: 66px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 20px;

      .productT_list_item_text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;

        span:first-child {
          font-weight: 600;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
        }

        span:nth-child(2) {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 17px;
        }
      }
    }
  }
}
