@import "app/global.scss";
.header {
  width: 100%;
  height: 68px;
  border-bottom: 1px solid #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  // width: calc(100% - 244px - 380px);
  // padding-right:244px;
  // padding-left:380px;
  // position: relative;
  z-index: 999;
  position: fixed;
  top: 0;

  .header_content {
    // width: 1166px;
    width: 1030px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .header_content_logo {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 24px;

      .header_content_logo_text {
        margin-left: 12px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span:first-child {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
        }

        span:nth-child(2) {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }
    }

    .header_content_testLogo {
      position: absolute;
      left: 198px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .header_content_testLogo_text {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        margin-left: 12px;
      }
    }

    .header_content_placeholder {
      flex: 1;
      margin-left: 20px;
      margin-right: 64px;
    }

    .header_content_search {
      flex: 1;
      margin-left: 20px;
      margin-right: 64px;
    }

    .header_content_btn {
      display: flex;
    }
  }
}
