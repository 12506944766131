@import "app/global.scss";
.itemsList {
  width: 540px;
  border-radius: 8px;
  padding-top: 2px;

  .itemsList_item {
    padding: 10px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .itemsList_item_content {
      width: calc(100% - 90px);
      display: flex;

      .itemsList_item_content_image {
        margin-right: 12px;
      }

      .itemsList_item_content_details {
        height: 64px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-around;

        .itemsList_item_content_details_name {
          font-size: 16px;
          font-weight: 600;
          color: #333333;

          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .itemsList_item_content_details_mbtiProfile {
          font-size: 14px;
          font-family: 'Gilroy-Light';
          font-weight: 300;
          color: #333333;

          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .itemsList_item_content_details_works {
          font-size: 14px;
          font-weight: 400;
          color: #999999;

          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .itemsList_item_mbti {
      min-width: 48px !important;
      display: flex;
      height: 64px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 8px;

      .itemsList_item_mbti_topicName {
        font-size: 18px;
        font-family: 'Gilroy-ExtraBold';
        font-weight: 800;
        color: #333333;
      }

      .itemsList_item_mbti_enneagramType {
        font-size: 18px;
        font-family: 'Gilroy-ExtraBold';
        font-weight: 800;
        color: #999999;
      }
    }
  }

  .itemsList_skeleton {
    height: 64px;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .itemsList_skeleton_content {
      display: flex;

      .itemsList_skeleton_content_details {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-around;

        .name {
          :global {
            .ant-skeleton-input {
              height: 16px !important;
            }
          }
        }

        .mbtiProfile,
        .works {
          :global {
            .ant-skeleton-input {
              height: 14px;
            }
          }
        }
      }
    }

    :global {
      .ant-skeleton-avatar {
        border-radius: 8px !important;
        width: 64px !important;
        height: 64px !important;
        margin-right: 12px;
      }
    }

    .itemsList_skeleton_mbti {
      display: flex;
      flex-direction: column;
      justify-content: center;

      :global {
        .ant-skeleton-button {
          height: 18px !important;
          margin-bottom: 4px;
        }
      }
    }
  }
}
