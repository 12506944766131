@import "app/global.scss";
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  :global {
    .ant-pagination-item-link {
      background: #ffffff !important;
      border-radius: 4px !important;
      margin-right: 12px !important;
    }
    .ant-pagination-item {
      background: #ffffff !important;
      border-radius: 4px !important;
      color: #956e7d !important;
      margin-right: 12px !important;
    }
    .ant-pagination-item-active {
      background: #956e7d !important;
      border-radius: 4px !important;
      font-family: 'Gilroy-Light' !important;
      font-weight: 300 !important;
      font-size: 14px !important;
      margin-right: 12px !important;

      a {
        color: #ffffff !important;
      }
    }
  }
}
