@import "app/global.scss";
.qrcodeModal {
  :global {
    .ant-modal {
      height: 400px !important;
      .ant-modal-content {
        height: 400px !important;
        padding: 0 !important;
        border-radius: 0px !important;
      }
    }
  }
}
