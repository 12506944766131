@import "app/global.scss";
.homeContent {
  display: flex;
  .homeContent_classAside {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #3d3d3d;
      line-height: 28px;
    }

    .characterSelect {
      margin-bottom: 12px !important;

      :global {
        .ant-select-selector {
          background: #f5f5f5 !important;
          border-radius: 10px !important;
          border: 1px solid #dddddd;

          .ant-select-selection-item {
            text-align: center !important;
          }

          .ant-select-selection-placeholder {
            font-family: 'Gilroy-ExtraBold';
            font-weight: 800;
            font-size: 14px;
            width: 60px;
            color: #999999;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .classifyList {
      .classifyList_item {
        width: 88px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        background-color: #fcfcf9;
        margin-bottom: 12px;

        transition:
          color 0.2s,
          background-color 0.2s,
          font-weight 0.2s;

        &:hover {
          background: #333333;
          font-weight: 600;
          color: #ffffff;
        }
      }

      .classifyList_selectdItem {
        background: #333333;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .verticalLine {
    width: 1px;
    height: 980px;
    background-color: #f1f1f1;
    margin-left: 26px;
    margin-right: 50px;
  }

  .homeContent_itemList {
    display: flex;
    flex-direction: column;
  }
}
