@import "app/global.scss";
.hotLinks {
  margin-left: 20px;
  :global {
    .ant-btn-link {
      height: 48px !important;
      padding: 0px !important;
      margin-right: 20px;
      font-size: 16px;
    }
  }
}
