@import "app/global.scss";
.worksList {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;

  .worksList_item {
    padding: 10px;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .worksList_item_image {
      display: flex;
      height: 100%;
      width: 62px;
      position: relative;
      margin-right: 14px;

      .worksList_item_image_0 {
        width: 44px;
        height: 64px;
        z-index: 10;

        img {
          width: 44px;
          height: 64px;
          border-radius: 8px;
          border: 1px solid #000000;
          object-fit: cover;
        }
      }

      .worksList_item_image_1 {
        width: 36px;
        height: 56px;
        z-index: 9;
        position: absolute;
        left: 18px;
        top: 8px;

        img {
          border-radius: 8px;
          border: 1px solid #000000;
          width: 36px;
          height: 56px;
          object-fit: cover;
        }
      }

      .worksList_item_image_2 {
        width: 28px;
        height: 48px;
        z-index: 8;
        position: absolute;
        left: 34px;
        top: 16px;

        img {
          border-radius: 8px;
          border: 1px solid #000000;
          width: 28px;
          height: 48px;
          object-fit: cover;
        }
      }
    }

    .worksList_item_content {
      width: calc(100% - 96px);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: start;

      .worksList_item_content_name {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;

        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .worksList_item_content_enName {
        font-size: 14px;
        font-family: Gilroy, Gilroy;
        font-weight: 300;
        color: #333333;
        max-width: 100%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .worksList_item_content_users {
        font-size: 14px;
        font-weight: 400;
        color: #999999;

        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
