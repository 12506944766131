@import "app/global.scss";

.characterSelect {
    margin-bottom: 12px !important;

    :global {
      .ant-select-selector {
        background: #f5f5f5 !important;
        border-radius: 10px !important;
        border: 1px solid #dddddd;

        .ant-select-selection-item {
          text-align: center !important;
        }

        .ant-select-selection-placeholder {
          font-family: 'Gilroy-ExtraBold';
          font-weight: 800;
          font-size: 14px;
          width: 60px;
          color: #999999;
          display: flex;
          justify-content: center;
        }
      }
    }
  }